<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Tất cả bản quyền từ năm 2021 thuộc về -
      <b-link
        class="ml-25"
        :href="$infoPortal().href"
        target="_blank"
      > Sở Lao động Thương binh và Xã hội tỉnh {{ $infoPortal().province }}</b-link>
      v1.14.23.25.10.23
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },

}
</script>
